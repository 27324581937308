<template>
  <div>
    <div class="pt-4 mt-4">
      <agile
      class="d-none d-sm-none d-md-block"
        autoplay="autoplay"
        autoplaySpeed="4000"
        fade="fade"
        pause-on-hover="pause-on-hover"
        pause-on-dots-hover="pause-on-dots-hover"
      >
        <div class="slide car-1">
          <div class="text-center mx-5">
            <h1 class="car-title">Grupo Versátil</h1>
            <h3 class="car-sub pt-5">
              Cercas y Mallas, Reja Forte, Púas, Concertina, Maderaplas, Follaje
              artificial y más...
            </h3>
          </div>
        </div>
        <div class="slide car-2">
          <div class="text-center mx-5">
            <h1 class="car-title">
              Con Presencia en el 80% de la República Mexicana
            </h1>
            <h3 class="car-sub2 pt-5">
              Comunícate por Nuestros Medios de Contacto
            </h3>
          </div>
        </div>
        <div class="slide car-3">
          <div class="text-center mx-5">
            <h1 class="car-title">Grupo Versátil</h1>
          <h3 class="car-sub2 pt-5">
              Cercas y Mallas, Reja Forte, Púas, Concertina, Maderaplas, Follaje
              artificial y más...
            </h3>
          </div>
        </div>
      </agile>
      <agile
      class="d-block d-sm-block d-md-none"
        autoplay="autoplay"
        autoplaySpeed="4000"
        fade="fade"
        pause-on-hover="pause-on-hover"
        pause-on-dots-hover="pause-on-dots-hover"
      >
        <div class="slide car-1-mobile">
          <div class="text-center mx-5">
            <h1 class="car-title-mobile">Grupo Versátil</h1>
            <h3 class="car-sub-mobile pt-5">
              Cercas y Mallas, Reja Forte, Púas, Concertina, Maderaplas, Follaje
              artificial y más...
            </h3>
          </div>
        </div>
        <div class="slide car-2-mobile">
          <div class="text-center mx-5">
            <h1 class="car-title-mobile">
              Con Presencia en el 80% de la República Mexicana
            </h1>
            <h3 class="car-sub2-mobile pt-1">
              Comunícate por Nuestros Medios de Contacto
            </h3>
          </div>
        </div>
        <div class="slide car-3-mobile">
          <div class="text-center mx-5">
            <h1 class="car-title-mobile">Grupo Versátil</h1>
          <h3 class="car-sub2-mobile pt-5">
              Cercas y Mallas, Reja Forte, Púas, Concertina, Maderaplas, Follaje
              artificial y más...
            </h3>
          </div>
        </div>
      </agile>
    </div>
    <div class="container mt-5">
      <div>
        <mdb-breadcrumb>
          <mdb-breadcrumb-item
            ><router-link to="/" class="red-text"
              >Inicio</router-link
            ></mdb-breadcrumb-item
          >
          <mdb-breadcrumb-item v-if="categoria"
            ><a class="red-text" @click="reload"
              >Productos</a
            ></mdb-breadcrumb-item
          >
          <mdb-breadcrumb-item v-else active>Productos</mdb-breadcrumb-item>
          <mdb-breadcrumb-item active>{{ categoria }}</mdb-breadcrumb-item>
        </mdb-breadcrumb>
      </div>
      <div class="d-block d-sm-block d-md-none">
        <div class="mx-4">
          <mdb-container class="red-text">
            <div>
              <mdb-side-nav
                sideNavClass="danger-color"
                mask="strong"
                :OpenedFromOutside.sync="toggleA"
                hidden
              >
                <div class="container text-center">
                  <h4 class="font-weight-bold mt-4">Categorías:</h4>
                </div>
                <hr />
                <div class="container text-center pl-0">
                  <router-link to="/producto/21"
                    ><mdb-card
                      class="mi-card-mallaciclonica mi-card-3 mb-5 hoverable"
                    >
                      <mdb-card-body class="text-center pt-4 mt-3">
                        <h5 class="h5-mobile">
                          <strong>Malla ciclonica</strong>
                        </h5>
                      </mdb-card-body>
                    </mdb-card></router-link
                  >
                  <router-link to="/producto/28"
                    ><mdb-card
                      class="mi-card-reja mi-card-3 mb-2 my-5 hoverable"
                    >
                      <mdb-card-body class="text-center pt-4 mt-3">
                        <h5 class="h5-mobile">
                          <strong>Rejas de acero</strong>
                        </h5>
                      </mdb-card-body>
                    </mdb-card></router-link
                  >
                  <router-link to="/producto/40"
                    ><mdb-card
                      class="mi-card-cercaselectricas mi-card-3 mb-2 my-5 hoverable"
                    >
                      <mdb-card-body class="text-center pt-4 mt-3">
                        <h5 class="h5-mobile">
                          <strong>Cercas electricas</strong>
                        </h5>
                      </mdb-card-body>
                    </mdb-card></router-link
                  >
                  <router-link to="/producto/13"
                    ><mdb-card
                      class="mi-card-concertina mi-card-3 mb-2 my-5 hoverable"
                    >
                      <mdb-card-body class="text-center pt-4 mt-3">
                        <h5 class="h5-mobile"><strong>Concertina</strong></h5>
                      </mdb-card-body>
                    </mdb-card></router-link
                  >
                  <a @click="filterCategory('Otras Mallas')"
                    ><mdb-card class="mi-card-e1 mi-card-3 mb-2 my-5 hoverable">
                      <mdb-card-body class="text-center pt-0 mt-4">
                        <h5 class="h5-mobile pb-0 mb-0">
                          <strong>Triple torsión</strong>
                        </h5>
                        <h5 class="h5-mobile my-0 py-0">
                          <strong>Hexagonal</strong>
                        </h5>
                        <h5 class="h5-mobile mt-0 pt-0">
                          <strong>Gaviones</strong>
                        </h5>
                      </mdb-card-body>
                    </mdb-card></a
                  >
                  <a @click="filterCategory('Sombra y Mosquitera')"
                    ><mdb-card class="mi-card-e3 mi-card-3 mb-2 my-5 hoverable">
                      <mdb-card-body class="text-center pt-3 mt-3">
                        <h5 class="h5-mobile">
                          <strong>Malla sombra y Malla mosquitera</strong>
                        </h5>
                      </mdb-card-body>
                    </mdb-card></a
                  >
                  <a @click="filterCategory('Perfil y Zig Zag')"
                    ><mdb-card class="mi-card-e4 mi-card-3 mb-2 my-5 hoverable">
                      <mdb-card-body class="text-center pt-3 mt-3">
                        <h5 class="h5-mobile">
                          <strong>Perfil sujetador y Alambre Zig Zag</strong>
                        </h5>
                      </mdb-card-body>
                    </mdb-card></a
                  >
                  <a @click="filterCategory('Mallas para Animales')"
                    ><mdb-card class="mi-card-e5 mi-card-3 mb-2 my-5 hoverable">
                      <mdb-card-body class="text-center pt-0 mt-4">
                        <h5 class="h5-mobile my-0 py-0">
                          <strong>Malla borreguera</strong>
                        </h5>
                        <h5 class="h5-mobile my-0 py-0">
                          <strong>Multimalla</strong>
                        </h5>
                        <h5 class="h5-mobile my-0 py-0">
                          <strong>Criba</strong>
                        </h5>
                      </mdb-card-body>
                    </mdb-card></a
                  >
                  <a @click="filterCategory('Metal y Laminas')"
                    ><mdb-card class="mi-card-e7 mi-card-3 mb-2 my-5 hoverable">
                      <mdb-card-body class="text-center pt-0 mt-4">
                        <h5 class="h5-mobile my-0 py-0">
                          <strong>Metal deplegado</strong>
                        </h5>
                        <h5 class="h5-mobile my-0 py-0">
                          <strong>Lamina perforada</strong>
                        </h5>
                        <h5 class="h5-mobile my-0 py-0">
                          <strong>Láminas</strong>
                        </h5>
                      </mdb-card-body>
                    </mdb-card></a
                  >
                  <a @click="filterCategory('Tuberia')"
                    ><mdb-card
                      class="mi-card-tuberia mi-card-3 mb-2 my-5 hoverable"
                    >
                      <mdb-card-body class="text-center pt-4 mt-3">
                        <h5 class="h5-mobile">
                          <strong>Tuberia para cercas</strong>
                        </h5>
                      </mdb-card-body>
                    </mdb-card></a
                  >
                  <a @click="filterCategory('Tubo Galvanizado')"
                    ><mdb-card
                      class="mi-card-tubo mi-card-3 mb-2 my-5 hoverable"
                    >
                      <mdb-card-body class="text-center pt-3 mt-3">
                        <h5 class="h5-mobile">
                          <strong>Tubo galvanizado y PTR galvanizado</strong>
                        </h5>
                      </mdb-card-body>
                    </mdb-card></a
                  >
                  <a
                    target="_blank"
                    href="https://www.sistemasdeseguridadyproteccion.com/"
                    ><mdb-card class="mi-card-ss mi-card-3 mb-2 my-5 hoverable">
                      <mdb-card-body class="text-center pt-4 mt-3">
                        <h5 class="h5-mobile">
                          <strong>Sistemas de seguridad</strong>
                        </h5>
                      </mdb-card-body>
                    </mdb-card></a
                  >
                  <router-link to="/producto/36"
                    ><mdb-card
                      class="mi-card-mallacinta mi-card-3 mb-2 my-5 hoverable"
                    >
                      <mdb-card-body class="text-center pt-4 mt-3">
                        <h5 class="h5-mobile">
                          <strong>Cinta plastica</strong>
                        </h5>
                      </mdb-card-body>
                    </mdb-card></router-link
                  >
                  <router-link to="/producto/35"
                    ><mdb-card
                      class="mi-card-mallasombra mi-card-3 mb-2 my-5 hoverable"
                    >
                      <mdb-card-body class="text-center pt-4 mt-3">
                        <h5 class="h5-mobile"><strong>Malla sombra</strong></h5>
                      </mdb-card-body>
                    </mdb-card></router-link
                  >
                  <router-link to="/producto/23"
                    ><mdb-card
                      class="mi-card-mallamosquitera mi-card-3 mb-2 my-5 hoverable"
                    >
                      <mdb-card-body class="text-center pt-4 mt-3">
                        <h5 class="h5-mobile">
                          <strong>Malla mosquitera</strong>
                        </h5>
                      </mdb-card-body>
                    </mdb-card></router-link
                  >
                  <router-link to="/producto/20"
                    ><mdb-card
                      class="mi-card-mallaborreguera mi-card-3 mb-2 my-5 hoverable"
                    >
                      <mdb-card-body class="text-center pt-4 mt-3">
                        <h5 class="h5-mobile">
                          <strong>Malla borreguera</strong>
                        </h5>
                      </mdb-card-body>
                    </mdb-card></router-link
                  >
                  <router-link to="/producto/45"
                    ><mdb-card class="mi-card-e6 mi-card-3 mb-2 my-5 hoverable">
                      <mdb-card-body class="text-center pt-4 mt-3">
                        <h5 class="h5-mobile">
                          <strong>Cerca Tubular</strong>
                        </h5>
                      </mdb-card-body>
                    </mdb-card></router-link
                  >
                  <a @click="filterCategory('Alambre')"
                    ><mdb-card
                      class="mi-card-alambre mi-card-3 mb-2 my-5 hoverable"
                    >
                      <mdb-card-body class="text-center pt-4 mt-3">
                        <h5 class="h5-mobile"><strong>Alambres</strong></h5>
                      </mdb-card-body>
                    </mdb-card></a
                  >
                  <router-link to="/producto/25"
                    ><mdb-card
                      class="mi-card-metal mi-card-3 mb-2 my-5 hoverable"
                    >
                      <mdb-card-body class="text-center pt-4 mt-3">
                        <h5 class="h5-mobile">
                          <strong>Metal desplegado</strong>
                        </h5>
                      </mdb-card-body>
                    </mdb-card></router-link
                  >
                  <router-link to="/producto/19"
                    ><mdb-card
                      class="mi-card-laminas mi-card-3 mb-2 my-5 hoverable"
                    >
                      <mdb-card-body class="text-center pt-4 mt-3">
                        <h5 class="h5-mobile"><strong>Láminas</strong></h5>
                      </mdb-card-body>
                    </mdb-card></router-link
                  >
                  <a @click="filterCategory('Accesorios para cercas')"
                    ><mdb-card
                      class="mi-card-accesorios mi-card-3 mb-2 my-5 hoverable"
                    >
                      <mdb-card-body class="text-center pt-4 mt-3">
                        <h5 class="h5-mobile">
                          <strong>Accesorios para cercas</strong>
                        </h5>
                      </mdb-card-body>
                    </mdb-card></a
                  >
                  <router-link to="/producto/22"
                    ><mdb-card
                      class="mi-card-mallahexagonal mi-card-3 mb-2 my-5 hoverable"
                    >
                      <mdb-card-body class="text-center pt-4 mt-3">
                        <h5 class="h5-mobile">
                          <strong>Malla hexagonal</strong>
                        </h5>
                      </mdb-card-body>
                    </mdb-card></router-link
                  >
                  <router-link to="/producto/26"
                    ><mdb-card
                      class="mi-card-multimalla mi-card-3 mb-2 my-5 hoverable"
                    >
                      <mdb-card-body class="text-center pt-4 mt-3">
                        <h5 class="h5-mobile"><strong>Multimalla</strong></h5>
                      </mdb-card-body>
                    </mdb-card></router-link
                  >
                  <router-link to="/follaje"
                    ><mdb-card
                      class="mi-card-follaje mi-card-3 mb-2 my-5 hoverable"
                    >
                      <mdb-card-body class="text-center pt-4 mt-3">
                        <h5 class="h5-mobile">
                          <strong>Follaje artificial</strong>
                        </h5>
                      </mdb-card-body>
                    </mdb-card></router-link
                  >
                  <a
                    target="_blank"
                    href="https://www.desarrollointegralcanino.com/"
                    ><mdb-card
                      class="mi-card-seguridadcanina mi-card-3 mb-2 my-5 hoverable"
                    >
                      <mdb-card-body class="text-center pt-4 mt-3">
                        <h5 class="h5-mobile">
                          <strong>Seguridad canina</strong>
                        </h5>
                      </mdb-card-body>
                    </mdb-card></a
                  >
                  <router-link to="/maderaplas"
                    ><mdb-card
                      class="mi-card-madera mi-card-3 mb-2 my-5 hoverable"
                    >
                      <mdb-card-body class="text-center pt-4 mt-3">
                        <h5 class="h5-mobile"><strong>Maderaplas</strong></h5>
                      </mdb-card-body>
                    </mdb-card></router-link
                  >
                </div>
              </mdb-side-nav>
            </div>
            <div class="text-center icon-div py-2">
              <mdb-btn
                color="red"
                block
                rounded
                @click.native="toggleLeftSideNav"
                iconRight
                icon="search"
                >Buscar productos</mdb-btn
              >
            </div>
          </mdb-container>
        </div>
        <div v-if="categoria" class="text-center mt-2">
          <a @click="reload()"
            ><mdb-btn color="danger" size="sm" rounded
              >x Eliminar filtros</mdb-btn
            ></a
          >
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 d-none d-sm-none d-md-block">
          <div>
            <h5 class="font-weight-bold mt-2">Categorías:</h5>
          </div>
          <div v-if="categoria" class="text-center mt-2">
            <a @click="reload()"
              ><mdb-btn color="danger" size="sm" rounded
                >x Eliminar filtros</mdb-btn
              ></a
            >
          </div>
          <div class="mt-4">
            <router-link to="/producto/21"
              ><mdb-card
                class="mi-card-mallaciclonica mi-card-3 mb-2 hoverable"
              >
                <mdb-card-body class="text-center pt-4 mt-3">
                  <h5><strong>Malla ciclonica</strong></h5>
                </mdb-card-body>
              </mdb-card></router-link
            >
            <router-link to="/producto/28"
              ><mdb-card class="mi-card-reja mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-4 mt-3">
                  <h5><strong>Rejas de acero</strong></h5>
                </mdb-card-body>
              </mdb-card></router-link
            >
            <router-link to="/producto/40"
              ><mdb-card
                class="mi-card-cercaselectricas mi-card-3 mb-2 hoverable"
              >
                <mdb-card-body class="text-center pt-4 mt-3">
                  <h5><strong>Cercas electricas</strong></h5>
                </mdb-card-body>
              </mdb-card></router-link
            >
            <router-link to="/producto/13"
              ><mdb-card class="mi-card-concertina mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-4 mt-3">
                  <h5><strong>Concertina</strong></h5>
                </mdb-card-body>
              </mdb-card></router-link
            >
            <a @click="filterCategory('Otras Mallas')"
              ><mdb-card class="mi-card-e1 mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-0 mt-3">
                  <h5 class="pb-0 mb-0"><strong>Triple torsión</strong></h5>
                  <h5 class="my-0 py-0"><strong>Hexagonal</strong></h5>
                  <h5 class="mt-0 pt-0"><strong>Gaviones</strong></h5>
                </mdb-card-body>
              </mdb-card></a
            >
            <a @click="filterCategory('Sombra y Mosquitera')"
              ><mdb-card class="mi-card-e3 mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-3 mt-3">
                  <h5><strong>Malla sombra y Malla mosquitera</strong></h5>
                </mdb-card-body>
              </mdb-card></a
            >
            <a @click="filterCategory('Perfil y Zig Zag')"
              ><mdb-card class="mi-card-e4 mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-3 mt-3">
                  <h5><strong>Perfil sujetador y Alambre Zig Zag</strong></h5>
                </mdb-card-body>
              </mdb-card></a
            >
            <a @click="filterCategory('Mallas para Animales')"
              ><mdb-card class="mi-card-e5 mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-0 mt-3">
                  <h5 class="my-0 py-0"><strong>Malla borreguera</strong></h5>
                  <h5 class="my-0 py-0"><strong>Multimalla</strong></h5>
                  <h5 class="my-0 py-0"><strong>Criba</strong></h5>
                </mdb-card-body>
              </mdb-card></a
            >
            <a @click="filterCategory('Metal y Laminas')"
              ><mdb-card class="mi-card-e7 mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-0 mt-3">
                  <h5 class="my-0 py-0"><strong>Metal deplegado</strong></h5>
                  <h5 class="my-0 py-0"><strong>Lamina perforada</strong></h5>
                  <h5 class="my-0 py-0"><strong>Láminas</strong></h5>
                </mdb-card-body>
              </mdb-card></a
            >
            <a @click="filterCategory('Tuberia')"
              ><mdb-card class="mi-card-tuberia mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-4 mt-3">
                  <h5><strong>Tuberia para cercas</strong></h5>
                </mdb-card-body>
              </mdb-card></a
            >
            <a @click="filterCategory('Tubo Galvanizado')"
              ><mdb-card class="mi-card-tubo mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-3 mt-3">
                  <h5><strong>Tubo galvanizado y PTR galvanizado</strong></h5>
                </mdb-card-body>
              </mdb-card></a
            >
            <a
              target="_blank"
              href="https://www.sistemasdeseguridadyproteccion.com/"
              ><mdb-card class="mi-card-ss mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-4 mt-3">
                  <h5><strong>Sistemas de seguridad</strong></h5>
                </mdb-card-body>
              </mdb-card></a
            >
            <router-link to="/producto/36"
              ><mdb-card class="mi-card-mallacinta mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-4 mt-3">
                  <h5><strong>Cinta plastica</strong></h5>
                </mdb-card-body>
              </mdb-card></router-link
            >
            <router-link to="/producto/35"
              ><mdb-card class="mi-card-mallasombra mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-4 mt-3">
                  <h5><strong>Malla sombra</strong></h5>
                </mdb-card-body>
              </mdb-card></router-link
            >
            <router-link to="/producto/23"
              ><mdb-card
                class="mi-card-mallamosquitera mi-card-3 mb-2 hoverable"
              >
                <mdb-card-body class="text-center pt-4 mt-3">
                  <h5><strong>Malla mosquitera</strong></h5>
                </mdb-card-body>
              </mdb-card></router-link
            >
            <router-link to="/producto/20"
              ><mdb-card
                class="mi-card-mallaborreguera mi-card-3 mb-2 hoverable"
              >
                <mdb-card-body class="text-center pt-4 mt-3">
                  <h5><strong>Malla borreguera</strong></h5>
                </mdb-card-body>
              </mdb-card></router-link
            >
            <router-link to="/producto/45"
              ><mdb-card class="mi-card-e6 mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-4 mt-3">
                  <h5><strong>Cerca Tubular</strong></h5>
                </mdb-card-body>
              </mdb-card></router-link
            >
            <a @click="filterCategory('Alambre')"
              ><mdb-card class="mi-card-alambre mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-4 mt-3">
                  <h5><strong>Alambres</strong></h5>
                </mdb-card-body>
              </mdb-card></a
            >
            <router-link to="/producto/25"
              ><mdb-card class="mi-card-metal mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-4 mt-3">
                  <h5><strong>Metal desplegado</strong></h5>
                </mdb-card-body>
              </mdb-card></router-link
            >
            <router-link to="/producto/19"
              ><mdb-card class="mi-card-laminas mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-4 mt-3">
                  <h5><strong>Láminas</strong></h5>
                </mdb-card-body>
              </mdb-card></router-link
            >
            <a @click="filterCategory('Accesorios para cercas')"
              ><mdb-card class="mi-card-accesorios mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-4 mt-3">
                  <h5><strong>Accesorios para cercas</strong></h5>
                </mdb-card-body>
              </mdb-card></a
            >
            <router-link to="/producto/22"
              ><mdb-card
                class="mi-card-mallahexagonal mi-card-3 mb-2 hoverable"
              >
                <mdb-card-body class="text-center pt-4 mt-3">
                  <h5><strong>Malla hexagonal</strong></h5>
                </mdb-card-body>
              </mdb-card></router-link
            >
            <router-link to="/producto/26"
              ><mdb-card class="mi-card-multimalla mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-4 mt-3">
                  <h5><strong>Multimalla</strong></h5>
                </mdb-card-body>
              </mdb-card></router-link
            >
            <router-link to="/follaje"
              ><mdb-card class="mi-card-follaje mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-4 mt-3">
                  <h5><strong>Follaje artificial</strong></h5>
                </mdb-card-body>
              </mdb-card></router-link
            >
            <a target="_blank" href="https://www.desarrollointegralcanino.com/"
              ><mdb-card
                class="mi-card-seguridadcanina mi-card-3 mb-2 hoverable"
              >
                <mdb-card-body class="text-center pt-4 mt-3">
                  <h5><strong>Seguridad canina</strong></h5>
                </mdb-card-body>
              </mdb-card></a
            >
            <router-link to="/maderaplas"
              ><mdb-card class="mi-card-madera mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-4 mt-3">
                  <h5><strong>Maderaplas</strong></h5>
                </mdb-card-body>
              </mdb-card></router-link
            >
          </div>
        </div>
        <div class="col-md-9">
          <div class="row">
            <div
              class="col-md-3 my-3 mx-auto"
              v-for="item in products"
              :key="item.id"
            >
              <item-card
                data-aos="fade-up"
                :title="item.title"
                :price="item.price"
                :nuevo="item.nuevo"
                :sale="item.sale"
                :image="item.image"
                :categoria="item.category"
                :id="item.id"
                class="mx-auto"
                style="max-height: 380px; min-height: 350px"
              />
            </div>
          </div>
          <keep-alive>
            <div v-if="tubo">
              <nested-product :id="30" class="my-4" :allItems="items" />
              <nested-product :id="18" class="my-4" :allItems="items" />
            </div>
          </keep-alive>
          <keep-alive>
            <div v-if="alambre">
              <nested-product :id="9" class="my-4" :allItems="items" />
              <nested-product :id="11" class="my-4" :allItems="items" />
              <nested-product :id="46" class="my-4" :allItems="items" />
              <nested-product :id="10" class="my-4" :allItems="items" />
              <nested-product :id="15" class="my-4" :allItems="items" />
            </div>
          </keep-alive>
          <keep-alive>
            <div v-if="tuberia">
              <nested-product :id="29" class="my-4" :allItems="items" />
              <nested-product :id="27" class="my-4" :allItems="items" />
              <nested-product :id="38" class="my-4" :allItems="items" />
              <nested-product :id="18" class="my-4" :allItems="items" />
            </div>
          </keep-alive>
          <keep-alive>
            <div v-if="m1">
              <nested-product :id="23" class="my-4" :allItems="items" />
              <nested-product :id="35" class="my-4" :allItems="items" />
            </div>
          </keep-alive>
          <keep-alive>
            <div v-if="m2">
              <nested-product :id="15" class="my-4" :allItems="items" />
              <nested-product :id="16" class="my-4" :allItems="items" />
            </div>
          </keep-alive>
          <keep-alive>
            <div v-if="m3">
              <nested-product :id="20" class="my-4" :allItems="items" />
              <nested-product :id="26" class="my-4" :allItems="items" />
              <nested-product :id="14" class="my-4" :allItems="items" />
            </div>
          </keep-alive>
          <keep-alive>
            <div v-if="m4">
              <nested-product :id="25" class="my-4" :allItems="items" />
              <nested-product :id="43" class="my-4" :allItems="items" />
              <nested-product :id="19" class="my-4" :allItems="items" />
            </div>
          </keep-alive>
          <keep-alive>
            <div v-if="m5">
              <nested-product :id="42" class="my-4" :allItems="items" />
              <nested-product :id="41" class="my-4" :allItems="items" />
              <nested-product :id="24" class="my-4" :allItems="items" />
              <nested-product :id="22" class="my-4" :allItems="items" />
              <nested-product :id="44" class="my-4" :allItems="items" />
              <nested-product :id="37" class="my-4" :allItems="items" />
            </div>
          </keep-alive>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mdbCard,
  mdbBreadcrumb,
  mdbBreadcrumbItem,
  mdbCardBody,
  mdbBtn,
  mdbSideNav,
  mdbContainer,
  waves,
} from "mdbvue";
import ItemCard from "../components/ItemCard";
import NestedProduct from "../components/nestedProduct.vue";
import { VueAgile } from "vue-agile";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    ItemCard,
    mdbBreadcrumb,
    mdbBreadcrumbItem,
    mdbBtn,
    NestedProduct,
    mdbSideNav,
    mdbContainer,
    agile: VueAgile,
  },
  data() {
    return {
      categoria: "",
      products: [...this.items],
      tubo: false,
      alambre: false,
      tuberia: false,
      m1: false,
      m2: false,
      m3: false,
      m4: false,
      m5: false,
      toggleA: false,
    };
  },
  mixins: [waves],
  methods: {
    toggleLeftSideNav() {
      this.toggleA = !this.toggleA;
    },
    reload() {
      this.categoria = "";
      this.m1 = false;
      this.m2 = false;
      this.m3 = false;
      this.m4 = false;
      this.m5 = false;
      this.tubo = false;
      this.alambre = false;
      this.tuberia = false;
      window.scrollTo(0, 600);
      this.$router.push({ path: "/productos" });
    },
    filterCategory(category) {
      switch (category) {
        case "Tubo Galvanizado":
          this.categoria = category;
          this.m1 = false;
          this.m2 = false;
          this.m3 = false;
          this.m4 = false;
          this.m5 = false;
          this.alambre = false;
          this.tuberia = false;
          this.tubo = true;
          window.scrollTo(0, 600);
          this.toggleA = false;
          break;
        case "Alambre":
          this.m1 = false;
          this.m2 = false;
          this.m3 = false;
          this.m4 = false;
          this.m5 = false;
          this.categoria = category;
          this.tubo = false;
          this.tuberia = false;
          this.alambre = true;
          window.scrollTo(0, 600);
          this.toggleA = false;
          break;
        case "Tuberia":
          this.m1 = false;
          this.m2 = false;
          this.m3 = false;
          this.m4 = false;
          this.m5 = false;
          this.categoria = category;
          this.alambre = false;
          this.tubo = false;
          this.tuberia = true;
          window.scrollTo(0, 600);
          this.toggleA = false;
          break;
        case "Sombra y Mosquitera":
          this.m2 = false;
          this.m3 = false;
          this.m4 = false;
          this.m5 = false;
          this.categoria = category;
          this.alambre = false;
          this.tubo = false;
          this.m1 = true;
          window.scrollTo(0, 600);
          this.toggleA = false;
          break;
        case "Perfil y Zig Zag":
          this.m1 = false;
          this.m3 = false;
          this.m4 = false;
          this.m5 = false;
          this.categoria = category;
          this.alambre = false;
          this.tubo = false;
          this.m2 = true;
          window.scrollTo(0, 600);
          this.toggleA = false;
          break;
        case "Mallas para Animales":
          this.m1 = false;
          this.m2 = false;
          this.m4 = false;
          this.m5 = false;
          this.categoria = category;
          this.alambre = false;
          this.tubo = false;
          this.m3 = true;
          this.toggleA = false;
          window.scrollTo(0, 600);
          break;
        case "Metal y Laminas":
          this.m1 = false;
          this.m2 = false;
          this.m3 = false;
          this.m5 = false;
          this.categoria = category;
          this.alambre = false;
          this.tubo = false;
          this.m4 = true;
          this.toggleA = false;
          window.scrollTo(0, 600);
          break;
        case "Otras Mallas":
          this.m1 = false;
          this.m2 = false;
          this.m3 = false;
          this.m4 = false;
          this.categoria = category;
          this.alambre = false;
          this.tubo = false;
          this.m5 = true;
          this.toggleA = false;
          window.scrollTo(0, 600);
          break;
        default:
          this.categoria = category;
          window.scrollTo(0, 600);
          this.m1 = false;
          this.m2 = false;
          this.m3 = false;
          this.m4 = false;
          this.m5 = false;
          this.tubo = false;
          this.alambre = false;
          this.tuberia = false;
          this.toggleA = false;
          break;
      }
    },
  },
  props: {
    items: Array,
  },
  watch: {
    categoria(nueva) {
      if (nueva != "") {
        let temp = [...this.items];
        let changes = temp.filter((item) => item.category == nueva);
        this.products = changes;
      } else {
        this.products = [...this.items];
      }
    },
  },
  mounted() {
    document.title = "Cercas y Mallas Versatiles - Productos";
    if (this.$route.params.category) {
      switch (this.$route.params.category) {
        case "Tubo Galvanizado":
          this.categoria = this.$route.params.category;
          this.tubo = true;
          break;
        case "Alambre":
          this.categoria = this.$route.params.category;
          this.alambre = true;
          break;
        case "Tuberia":
          this.categoria = this.$route.params.category;
          this.tuberia = true;
          break;
        case "Sombra y Mosquitera":
          this.categoria = this.$route.params.category;
          this.m1 = true;
          break;
        case "Perfil y Zig Zag":
          this.categoria = this.$route.params.category;
          this.m2 = true;
          break;
        case "Mallas para Animales":
          this.categoria = this.$route.params.category;
          this.m3 = true;
          break;
        case "Metal y Laminas":
          this.categoria = this.$route.params.category;
          this.m4 = true;
          break;
        case "Otras Mallas":
          this.categoria = this.$route.params.category;
          this.m5 = true;
          break;
        default:
          this.categoria = this.$route.params.category;
          break;
      }
    }
  },
};
</script>

<style>
.main {
  background: no-repeat center fixed;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../assets/malla-main.jpg");
  color: #e4e4e4;
}
.card-title-2 {
  color: #b71c1c;
  letter-spacing: 0.11em;
  -webkit-text-stroke: 1.5px #ffff;
  font-size: 1.5em;
  font-weight: 900;
}
.card-title-3 {
  color: #b71c1c;
  -webkit-text-stroke: 1.5px #ffff;
  font-size: 1.2em;
  font-weight: 900;
}
.mi-card-accesorios {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/accesorios.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-alambre {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/alambre.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-concertina {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/concertina.png") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-laminas {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/laminas.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-mallaciclonica {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/cercas2.jpeg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-metal {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/metaldesplegado.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-reja {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/rejadeacero.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-tubo {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/8.jpeg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-tuberia {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/2.jpeg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-mallaborreguera {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/mallaborreguera.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-mallacinta {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/mallaciclonicaconcintaplastica.jpg") center
      no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-mallahexagonal {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/mallahexagonal.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-mallamosquitera {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/mallamosquitera.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-mallasombra {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/mallasombra.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-multimalla {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/multimalla.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-seguridadcanina {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/seguridadcanina.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-cercaselectricas {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/cercaselectricas.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-ss {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/circuito.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-follaje {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/follaje/follaje/10.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-madera {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/madera/madera005.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-e1 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/1.jpeg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-e3 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/3.jpeg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-e4 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/4.jpeg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-e5 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/5.jpeg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-e6 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/6.jpeg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-e7 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/7.jpeg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-3:hover,
.mi-card-3:focus,
.mi-card-3:active {
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.h5-mobile {
  font-size: 1.1rem;
}

.agile__nav-button {
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  transition-duration: 0.3s;
  width: 80px;
}
.agile__nav-button:hover {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}
.agile__nav-button--prev {
  left: 0;
}
.agile__nav-button--next {
  right: 0;
}
.agile__dots {
  bottom: 10px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}
.agile__dot {
  margin: 0 10px;
}
.agile__dot button {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
  width: 10px;
}
.agile__dot--current button,
.agile__dot:hover button {
  background-color: #fff;
}

.slide {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.car-1 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../assets//carousel/1.jpg") center no-repeat;
  background-size: cover;
  height: 600px;
}
.car-2 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../assets//carousel/2.jpg") center no-repeat;
  background-size: cover;
  height: 600px;
}
.car-3 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../assets//carousel/3.jpg") center no-repeat;
  background-size: cover;
  height: 600px;
}
.car-1-mobile {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../assets//carousel/1m.jpg") center no-repeat;
  background-size: cover;
  height: 350px;
}
.car-2-mobile {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../assets//carousel/2m.jpg") center no-repeat;
  background-size: cover;
  height: 350px;
}
.car-3-mobile {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../assets//carousel/3m.jpg") center no-repeat;
  background-size: cover;
  height: 350px;
}
.car-title {
  color: rgb(196, 7, 7);
  -webkit-text-stroke: 3px white;
  font-size: 4.5rem;
  font-weight: bold;
  font-style: italic;
}
.car-sub {
  color: white;
  -webkit-text-stroke: 2px rgb(13, 13, 112);
  font-size: 3rem;
  font-weight: bold;
  font-style: italic;
}
.car-sub2 {
  color: rgb(218, 179, 9);
  -webkit-text-stroke: 2px rgb(255, 255, 255);
  font-size: 3rem;
  font-weight: bold;
  font-style: italic;
}
.car-title-mobile {
  color: rgb(196, 7, 7);
  -webkit-text-stroke: 2px white;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
}
.car-sub-mobile  {
  color: white;
  -webkit-text-stroke: 1px rgb(13, 13, 112);
  font-size: 1rem;
  font-weight: bold;
  font-style: italic;
}
.car-sub2-mobile  {
  color: rgb(218, 179, 9);
  -webkit-text-stroke: 1px rgb(255, 255, 255);
  font-size: 1rem;
  font-weight: bold;
  font-style: italic;
}
</style>