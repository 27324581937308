<template>
    <div class="card card-ecommerce hoverable py-4 px-4">
        <div class="row mt-1">
        <div class="col-md-12">
          <div class="row mt-3">
            <div class="col-md-6 text-center">
              <img
                v-if="product.image"
                data-aos="fade-in"
                :src="require(`../assets/products/${product.image}`)"
                class="img-fluid"
                alt=""
                style="max-height: 440px"
              />
            </div>
            <div class="col-md-6">
              <h2 class="mb-4 mt-3">
                <strong>{{ product.title }}</strong>
              </h2>
              <p>
                {{ product.description }}
              </p>
              <div class="text-center">
                <a
                  target="_blank"
                  :href="`https://api.whatsapp.com/send?phone=5212293060014&text=Quiero%20hacer%20una%20cotizaci%C3%B3n%20de%20${product.title}%20`"
                  ><mdb-btn color="success" rounded size="lg"
                    >Cotizar<mdb-icon
                      fab
                      icon="whatsapp"
                      class="ml-2" /></mdb-btn
                ></a>
                <a
                  target="_blank"
                  v-if="product.title == 'Cercas electricas'"
                  href="https://www.sistemasdeseguridadyproteccion.com/"
                  ><mdb-btn color="indigo darken-4" size="lg"
                    >Ver más</mdb-btn
                  ></a
                >
              </div>
            </div>
          </div>
          <div>
            <images
              class="mt-4"
              :category="product.category"
              :product="product.title"
            />
          </div>
            <div class="text-center">
                <router-link :to="`/producto/${product.id}`"
                ><mdb-btn color="danger" rounded size="lg"
                    >Ver Especificaciones</mdb-btn
                  ></router-link>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import {
  mdbBtn,
  mdbIcon,
} from "mdbvue";
import images from "../components/imagenes";

export default {
  components: {
    mdbBtn,
    mdbIcon,
    images,
  },
  data() {
    return {
      product: {},
    };
  },
  props: {
    allItems: Array,
    id: Number,
  },
  mounted() {
    this.getProduct();
  },
  updated() {
    this.getProduct();
  },
  methods: {
    getProduct() {
      this.allItems.forEach((item) => {
        if (item.id == this.id) {
          this.product = item;
        }
      });
    },
  },
};
</script>