<template>
  <div class="card card-ecommerce hoverable">
    <!-- Card image -->
    <router-link :to="`/producto/${id}`"
      ><div class="view overlay">
        <img
          :src="require(`../assets/products/${image}`)"
          class="img-fluid mx-auto"
          alt="sample image"
          style="max-height: 9.5rem"
        />
        <div class="mask rgba-white-slight" style="cursor: pointer;"></div></div
    ></router-link>
    <!-- Card image -->
    <!-- Card content -->
    <div class="card-body">
      <!-- Category & Title -->
      <h5 class="card-title mb-1 text-center">
        <strong>
          <p href="" class="dark-grey-text">{{ title }}</p>
        </strong>
      </h5>
      <!-- Card footer -->
      <div class="card-footer tex-center pb-0 px-0">
        <div class="row mx-auto mx-0 px-0 d-flex justify-content-center">
          <mdb-btn @click="goTo(id)" block rounded class="px-3" color="red darken-4" size="sm"
            ><strong>Ver producto</strong></mdb-btn
          >
          <br/>
<mdb-btn
            rounded
            tag="a"
            :href="`https://api.whatsapp.com/send?phone=5212293060014&text=Quiero%20hacer%20una%20cotizaci%C3%B3n%20de%20${title}%20`"
              color="success-color"
              class="success-color white-text mt-2"
              size="sm"
              block
              ><strong>Cotizar<mdb-icon fab icon="whatsapp" class="ml-2" /></strong></mdb-btn
          >
        </div>
      </div>
    </div>
    <!-- Card content -->
  </div>
</template>

<script>
import { mdbBtn, mdbIcon } from "mdbvue";

export default {
  props: {
    title: String,
    nuevo: Boolean,
    image: String,
    categoria: String,
    id: Number,
  },
  components: {
    mdbBtn,
    mdbIcon,
  },
  methods: {
    goTo (data) {
      this.$router.push({ path: `/producto/${data}` })
    }
  },
};
</script>

<style>
</style>